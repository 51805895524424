.Footer-container {
  position: relative;
}
.Footer-container > hr {
  border: 1px solid var(--lightgray);
}
.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  height: 20rem;
  justify-content: center;
  align-items: center;
}
.social-links {
  display: flex;
  gap: 4rem;
}
.social-links > img {
  heigth: 2rem;
  width: 2rem;
  cursor: pointer;
}
.logo-f > img {
  width: 15rem;
  backround: transparent;
}
.footer-blur-1 {
  bottom: 0rem;
  right: 15%;
  width: 26rem;
  filter: blur(200px);
  background: red;
}

.footer-blur-2 {
  bottom: 0rem;
  left: 15%;
  width: 26rem;
  filter: blur(200px);
  background: (255, 115, 0);
}
